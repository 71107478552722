import React from 'react'
import '../../scss/footer.scss'
import { FaSquarePhone, FaSquareWhatsapp, FaEnvelope } from 'react-icons/fa6'

export const Footer: React.FC = () => {
    return (
        <>
            <div className='footer'>
                <div className='footer__contacts'>
                    <div className='footer__contacts-address'>
                        <p> ООО "ЕвроПромГидравлик"</p>
                        <p>192289, Россия, Санкт-Петербург,</p>
                        <p>пр. Девятого января, д.57,лит.А-А1</p>
                    </div>

                    <div className='footer__contacts-tel'>
                        <p><FaSquarePhone size='1.5em' className='footer__contacts__icons' /><a href='tel:78123362937'>+7
                            (812)
                            336 29 37</a></p>
                        <p><FaSquareWhatsapp size='1.5em' className='footer__contacts__icons' /><a
                            href='https://wa.me/79658198225'>+7 (965) 819 82 25</a>
                        </p>
                        <p><FaEnvelope size='1.5em' className='footer__contacts__icons' /><a
                            href='mailto:info@avs-rus.ru'> info@avs-rus.ru</a></p>
                    </div>

                    <div className='footer__contacts-working'>
                        <p>Режим работы:</p>
                        <p>Пн-Чт: 9:00-17:30</p>
                        <p>Пт: 9:00-16:30</p>
                    </div>
                </div>

            </div>
        </>
    )
}