import React from 'react'

const NotFound: React.FC = () => {
    return (
        <div>
            Страница не найдена.
        </div>
    )
}

export default NotFound
