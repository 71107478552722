import vacImg from '../assets/img/vacancies.png'
import '../scss/vacancies.scss'
import { Helmet } from 'react-helmet'

const Vacancies: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Вакансии - ЕвроПромГидравлик</title>
                <meta name='description'
                      content='Вакансии в компании ЕвроПромГидравлик' />
            </Helmet>
            <h1>Вакансии</h1>
            <div className='vacancies'>
                <div className='vacancies__text'>
                    <p>Компании ЕвроПромГидравлик требуется:</p>
                    <div className='vacancies__text-vac'>
                        <h2><b>Токарь</b></h2>
                    </div>
                    <div>
                        <p><strong>Обязанности:</strong></p>
                        <ul className='vacancies__text-ul'>
                            <li><i>Изготовление деталей гидроцилиндров</i></li>
                            <li><i>Работа на универсальном токарном станке с УЦИ KC50B(CDS6250B/2000) (аналог 16К20),
                                ДИП-300</i></li>
                        </ul>
                        <p><strong>Требования:</strong></p>
                        <ul className='vacancies__text-ul'>
                            <li><i>Профильное образование по специальности «Токарь»</i></li>
                            <li><i>Опыт работы не менее 1 года</i></li>
                            <li><i>Документально подтвержденный опыт работы</i></li>
                        </ul>
                        <p><strong>Условия:</strong></p>
                        <ul className='vacancies__text-ul'>
                            <li><i>Полный рабочий день</i><i>рабочий день с 9.00 до 17.00 с понедельника по четверг,
                                пятница с 9.00 до 16.00</i></li>
                            <li><i>Ежегодная индексация зарплаты</i></li>
                            <li><i>Раздевалка, душевая, комната для отдыха и приема пищи</i></li>
                            <li><i>Спецодежда и СИЗ. Чай, кофе и сахар за счёт компании</i></li>
                            <li><i>Испытательный срок 1 месяц, заработная плата во время испытательного срока 90.000
                                рублей на руки</i></li>
                        </ul>
                    </div>
                    <div className='vacancies__text-contacts'>
                        <p>По всем вопросам обращаться:</p>
                        <p>Тел: +7 (812) 336 29 37</p>
                        <p>Email: info@avs-rus.ru</p>
                    </div>
                </div>
                <div className='vacancies__image'>
                    <img src={vacImg} alt='Вакансии в ЕвроПромГидравлик' />
                </div>
            </div>
        </>
    )
}

export default Vacancies
