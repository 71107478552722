import React from 'react'
import atosSert from '../assets/img/sertificates/Sertificate_Atos.jpg'
import marzSert from '../assets/img/sertificates/Sertificate_Marzocchi.jpg'
import omtSert from '../assets/img/sertificates/Sertificate_EIH-OMT.png'
import '../scss/sertificates.scss'
import { Helmet } from 'react-helmet'

const Sertificates: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Сертификаты - ЕвроПромГидравлик</title>
                <meta name='description'
                      content='Сертификаты дистрибьютора ЕвроПромГидравлик' />
            </Helmet>
            <h1>Сертификаты дистрибьютора</h1>
            <div className='sertificates'>
                <div className='sertificates__item'>
                    <h2>Atos</h2>
                    <img src={atosSert} alt='Сертификат дистрибьютора Atos' />
                </div>
                <div className='sertificates__item'>
                    <h2>Marzocchi</h2>
                    <img src={marzSert} alt='Сертификат дистрибьютора Marzocchi' />
                </div>
                <div className='sertificates__item'>
                    <h2>OMT</h2>
                    <img src={omtSert} alt='Сертификат дистрибьютора OMT' />
                </div>
            </div>
        </>
    )
}

export default Sertificates
