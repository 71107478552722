import React from 'react'
import { Slider } from './Slider'
import { Info } from './Info'
import { Manufacturers } from './Manufacturers'
import MainCategories from './MainCategories'
import IconLine from './IconLine'
import '../../scss/content.scss'
import About from './About'

export const Content: React.FC = () => {
    return (
        <>
            <Slider />
            <IconLine />
            <Info />
            <h1>Комплектующие для мобильной и промышленной гидравлики от ЕвроПромГидравлик</h1>
            <MainCategories />
            <About />
            <Manufacturers />
        </>
    )
}