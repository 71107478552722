import React from 'react'
import { LiaShippingFastSolid, LiaClockSolid, LiaGiftSolid, LiaWalletSolid } from 'react-icons/lia'
import '../../scss/iconLine.scss'

const IconLine: React.FC = () => {
    return (
        <div className='iconLine'>
            <div className='iconLine__card'>
                <div className='iconLine__card-item'>
                    <div className='icon-item'><LiaShippingFastSolid /></div>
                    <div className='text-item'>
                        <h3>БЫСТРАЯ ДОСТАВКА</h3>
                        <p>Доставка по всей России</p>
                    </div>
                </div>
                <div className='iconLine__card-item'>
                    <div className='icon-item'><LiaClockSolid /></div>
                    <div className='text-item'>
                        <h3>ГАРАНТИЯ 1 ГОД</h3>
                        <p>На узлы и механизмы</p>
                    </div>
                </div>
                <div className='iconLine__card-item'>
                    <div className='icon-item'><LiaWalletSolid /></div>
                    <div className='text-item'>
                        <h3>УДОБНАЯ ОПЛАТА</h3>
                        <p>Оплачивайте удобным способом</p>
                    </div>
                </div>
                <div className='iconLine__card-item'>
                    <div className='icon-item'><LiaGiftSolid /></div>
                    <div className='text-item'>
                        <h3>БЕСПЛАТНАЯ УПАКОВКА</h3>
                        <p>Получите упаковку при заказе</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconLine
