import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from './components/layouts/MainLayout.tsx'
import { Home } from './pages/Home.tsx'
import ProductCategory from './components/ProductCategory.tsx'
import ProductsPage from './pages/ProductsPage.tsx'
import Sertificates from './pages/Sertificates.tsx'
import Vacancies from './pages/Vacancies.tsx'
import Contacts from './pages/Contacts.tsx'
import NotFound from './pages/NotFound.tsx'
import './App.css'
import CategoriesPage from './pages/CategoriesPage.tsx'

const App = () => {
    return (

        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route path='' element={<Home />} />
                <Route path='/categories' element={<CategoriesPage />} />
                <Route path='/:categorySlug' element={<ProductCategory />} />
                <Route path='/:categorySlug/:subcategorySlug' element={<ProductsPage />} />
                <Route path='vacancies' element={<Vacancies />} />
                <Route path='sertificates' element={<Sertificates />} />
                <Route path='contacts' element={<Contacts />} />
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>

    )
}

export default App
