import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Breadcrumbs from '../components/Breadcrumbs'
import categoriesData from '../data/categories.json'
import '../scss/productsPage.scss'

interface Category {
    slug: string
    subcategories: Subcategory[]
}

interface Subcategory {
    slug: string
    h1: string
    description: string
    keywords: string
    details: Detail[];
}

interface Detail {
    id: number;
    title: string;
    text: string;
    files?: { name: string; url: string }[];
    image?: string;
}

const ProductsPage: React.FC = () => {
    const { categorySlug, subcategorySlug } = useParams<{ categorySlug: string, subcategorySlug: string }>()
    const category = categoriesData.find((category: Category) => category.slug === categorySlug)
    const subcategory = category?.subcategories.find((subcategory: Subcategory) => subcategory.slug === subcategorySlug)

    if (!subcategory) {
        return <div>Подкатегория не найдена</div>
    }

    return (
        <>
            <Helmet>
                <title>{`${subcategory.h1} - ЕвроПромГидравлик`}</title>
                <meta name='description' content={subcategory.description} />
                <meta name='keywords' content={subcategory.keywords} />
            </Helmet>
            <Breadcrumbs />
            <div className='product'>
                <h1>{subcategory.h1}</h1>

                {subcategory.details.map(detail => (
                    <div key={detail.id} className='product__item'>
                        <div className='product__item-text'>
                            <h2>{detail.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: detail.text }} />
                            {detail.files && detail.files.filter(file => file.url).map((file, index) => (
                                <a key={index} href={file.url} target='_blank' rel='noreferrer'>
                                    <div className='product__item-file'>{file.name}</div>
                                </a>
                            ))}
                        </div>
                        <div className='imageFile__image'>
                            {detail.image && <img src={detail.image} alt={detail.title} />}
                        </div>
                    </div>
                ))}

                <Link to={`/${categorySlug}`} className='back-button'>Назад</Link>
            </div>
        </>
    )
}

export default ProductsPage
