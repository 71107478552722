import React from 'react'
import { Helmet } from 'react-helmet'
import MainCategories from '../components/Home/MainCategories'

const CategoriesPage: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Категории - ЕвроПромГидравлик</title>
                <meta name='description' content='Категории' />
                <meta name='keywords'
                      content='Гидроцилиндры, маслостанции, гидравлические компоненты, пневматика, запорная арматура' />
            </Helmet>
            <h1>Категории товаров</h1>
            <MainCategories />
        </>
    )
}

export default CategoriesPage
