import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import categoriesData from '../data/categories.json'
import '../assets/img/categories/cilinders.jpg'
import '../scss/categoryPage.scss'

interface Subcategory {
    id: number;
    slug: string;
    name: string;
    image: string;
}

interface Category {
    slug: string;
    category: string;
    description: string;
    keywords: string;
    subcategories: Subcategory[];
}

const ProductCategory: React.FC = () => {
    const { categorySlug } = useParams<{ categorySlug: string }>()
    const [categoryData, setCategoryData] = useState<Category | null>(null)

    useEffect(() => {
        // Находим данные категории по categorySlug
        const selectedCategory = categoriesData.find((category: Category) => category.slug === categorySlug)

        if (selectedCategory) {
            setCategoryData(selectedCategory)
        }
    }, [categorySlug])

    if (!categoryData) {
        return <div>Загрузка...</div>
    }

    return (
        <div className='categoryPage'>

            <Helmet>
                <title>{`${categoryData.category} - ЕвроПромГидравлик`}</title>
                <meta name='description' content={categoryData.description} />
                <meta name='keywords' content={categoryData.keywords} />
            </Helmet>

            <h1>{categoryData.category}</h1>

            <div className='categoryPage__inner'>

                <ul className='categoryPage__subcats'>
                    {categoryData.subcategories && categoryData.subcategories.map((subcategory: Subcategory) => (
                        <li key={subcategory.id}>
                            <Link
                                to={`/${categorySlug}/${subcategory.slug}`}>
                                <img src={subcategory.image} alt={subcategory.name} /><h2>{subcategory.name}</h2></Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Link to={`/categories`} className='categories-back-button'>Все категории</Link>
        </div>
    )
}

export default ProductCategory