import React from 'react'
import info1 from '../../assets/img/info1.jpg'
import info2 from '../../assets/img/info2.jpg'
import info3 from '../../assets/img/info3.jpg'
import '../../scss/info.scss'

export const Info: React.FC = () => {
    return (
        <div className='info'>

            <div className='info__card desktop-only'>
                <div className='info__card__img'>
                    <img src={info1} alt='/' />
                </div>
                <div className='info__card__text'>
                    <h4>Собственное производство</h4>
                    <p>Гидроцилиндры и маслостанции собственного производства, независимо от типа исполнения,
                        имеют несколько десятков моделей. Различные габаритные размеры нашей продукции предполагают
                        удобство ее использования в широком спектре.</p>
                </div>
            </div>
            <div className='info__card desktop-only'>
                <div className='info__card__img'>
                    <img src={info2} alt='/' />
                </div>
                <div className='info__card__text'>
                    <h4>Проверка оборудования</h4>
                    <p>Мы регулярно калибруем и тестируем оборудование, маслостанции и гидроцилиндры собственного
                        производства, уделяя особое внимание опыту и квалификации наших специалистов.</p>
                </div>
            </div>
            <div className='info__card desktop-only'>
                <div className='info__card__img'>
                    <img src={info3} alt='/' />
                </div>
                <div className='info__card__text'>
                    <h4>Доставка</h4>
                    <p>Компания «ЕвроПромГидравлик» предлагает доставку комплектующих и оборудования по Санкт-Петербургу
                        и Ленинградской области, а также в любой регион Российской Федерации.</p>
                </div>
            </div>
        </div>
    )
}