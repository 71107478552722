import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../logo.png'
import waIcon from '../../assets/img/whatsapp.svg'
import menuIcon from '../../assets/img/menu.svg'
import '../../scss/header.scss'

export const Header: React.FC = () => {
    const [isOpen, setOpen] = useState<boolean>(false)
    const [openSubmenu, setOpenSubmenu] = useState<number | null>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isOpen && !((event.target as HTMLElement).closest('.header__nav')) && !((event.target as HTMLElement).closest('.header__menu-button'))) {
                setOpen(false)
            }
            if (openSubmenu !== null && !((event.target as HTMLElement).closest('.submenu')) && !((event.target as HTMLElement).closest('.header__nav-item'))) {
                setOpenSubmenu(null)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isOpen, openSubmenu])

    const handleMenuItemClick = () => {
        setOpen(false)
    }

    return (
        <>
            <div className='header'>
                <div className='header__logo'>
                    <Link to='/'><img src={logo} alt='' /></Link>
                </div>
                <nav className={`header__nav ${isOpen ? 'active' : ''}`}>
                    <ul className='header__nav-list'>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <Link to='/' className='header__nav-item__link'>Главная</Link>
                        </li>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <Link to='/categories' className='header__nav-item__link'>Продукция</Link>
                        </li>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <Link to='sertificates'>Сертификаты</Link>
                        </li>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <Link to='vacancies'>Вакансии</Link>
                        </li>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <Link to='contacts'>Контакты</Link></li>
                        <li className='header__nav-item' onClick={handleMenuItemClick}>
                            <a href='https://wa.me/79658198225'>
                                <img src={waIcon} alt='Чат в WhatsApp' />
                            </a>

                        </li>
                    </ul>
                </nav>
                <button className='header__menu-button' onClick={() => setOpen(!isOpen)}>
                    <img src={menuIcon} alt='' />
                </button>
            </div>
        </>
    )
}
