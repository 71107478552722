import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

import slide1Webp from '../../assets/img/pump-slide.webp'
import slide1Png from '../../assets/img/pump-slide.png'
import slide2Webp from '../../assets/img/hydro-slide.webp'
import slide2Png from '../../assets/img/hydro-slide.png'
import slide3Webp from '../../assets/img/zap-arm-slide.webp'
import slide3Png from '../../assets/img/zap-arm-slide.png'
import slide4Webp from '../../assets/img/pnevmatika-slide.webp'
import slide4Png from '../../assets/img/pnevmatika-slide.png'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import '../../scss/slider.scss'

export const Slider: React.FC = React.memo(() => {

    useEffect(() => {
        const preloadImages = [slide1Webp, slide1Png, slide2Webp, slide2Png, slide3Webp, slide3Png, slide4Webp, slide4Png]
        preloadImages.forEach(image => {
            new Image().src = image
        })
    }, [])

    return (
        <div className='slider'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                rewind={true}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false
                }}
                pagination={{
                    clickable: true
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className='mySwiper'
            >
                <SwiperSlide>
                    <div className='slide'>
                        <div className='text'>
                            <h2>Насосы</h2>
                            <h3>Marzocchi, Atos, Salami, Contarini</h3>
                            <ul>
                                <li>пластинчатые насосы</li>
                                <li>радиально-поршневые насосы</li>
                                <li>шестеренные насосы</li>
                                <li>многосекционные насосы</li>
                                <li>насосы с ручной регулировкой</li>
                            </ul>
                        </div>
                        <picture>
                            <source srcSet={slide1Webp} type='image/webp' />
                            <source srcSet={slide1Png} type='image/png' />
                            <img loading='lazy' src={slide1Png} alt='/' />
                        </picture>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide'>
                        <div className='text'>
                            <h2>Гидравлика</h2>
                            <p>
                                «ЕвроПромГидравлик» предлагает широкий спектр гидравлического оборудования и
                                комплектующих. Благодаря длительным партнёрским отношениям с заводами-изготовителями мы
                                можем предложить конкурентноспособные цены на весь спектр промышленного оборудования,
                                расходные материалы и комплектующие.
                            </p>
                        </div>
                        <picture>
                            <source srcSet={slide2Webp} type='image/webp' />
                            <source srcSet={slide2Png} type='image/png' />
                            <img loading='lazy' src={slide2Png} alt='/' />
                        </picture>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide'>
                        <div className='text'>
                            <h2>Запорная арматура</h2>
                            <h3>VALBIA, AVS, M+M, GSR</h3>
                            <ul>
                                <li>Шаровые краны</li>
                                <li>задвижки</li>
                                <li>дисковые затворы с пневмоприводом и электроприводом</li>
                                <li>датчики положения</li>
                                <li>электро-пневматические позиционеры</li>
                                <li>распределители для управления приводами</li>
                            </ul>
                        </div>
                        <picture>
                            <source srcSet={slide3Webp} type='image/webp' />
                            <source srcSet={slide3Png} type='image/png' />
                            <img loading='lazy' src={slide3Png} alt='/' />
                        </picture>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide'>
                        <div className='text'>
                            <h2>Пневматика</h2>
                            <p>
                                Продукция отличается исключительно высоким качеством, надежностью, сертифицирована по
                                стандарту качества ISO 9001 и взаимозаменяема по присоединительным и габаритным размерам
                                с аппаратурой других европейских фирм.

                                Все пневматическое оборудование с комплектующими имеет сертификат соответствия
                                Госстандарта России.
                            </p>
                        </div>
                        <picture>
                            <source srcSet={slide4Webp} type='image/webp' />
                            <source srcSet={slide4Png} type='image/png' />
                            <img loading='lazy' src={slide4Png} alt='/' />
                        </picture>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
})
