import React from 'react'
import { Link } from 'react-router-dom'
import categoriesData from '../../data/categories.json'
import '../../scss/categories.scss'

interface Category {
    id: number;
    slug: string;
    category: string;
    image: string;
}

const MainCategories: React.FC = () => {
    const categories: Category[] = categoriesData

    return (
        <>
            <div className='categories'>
                <ul className='categories__items'>
                    {categories.map((category) => (
                        <li key={category.id} className='categories__items-item'>
                            <Link to={`/${category.slug}`}>
                                <img src={category.image} alt={category.category} />
                                <p>{category.category}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default MainCategories
