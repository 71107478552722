import React from 'react'
import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps'
import '../scss/footer.scss'

export const YandexMap: React.FC = () => {
    return (
        <>
            <YMaps query={{ apikey: 'ea67075d-b80e-481f-b7cd-77594f75e2cc' }}>
                <Map className='mapProp'
                     defaultState={{
                         center: [59.8461711600918, 30.437754200373508],
                         zoom: 16,
                         controls: ['zoomControl', 'fullscreenControl']
                     }}
                     modules={['control.ZoomControl', 'control.FullscreenControl']}
                >
                    <Placemark defaultGeometry={[59.84593362492768, 30.440307663355703]} />
                </Map>
            </YMaps>
        </>
    )
}