import React from 'react'
import dataImg from '../../data/images.json'
import '../../scss/manufacturers.scss'

interface Item {
    id: number
    image: string
    alt: string
}

export const Manufacturers: React.FC = () => {
    const items: Item[] = dataImg

    return (
        <div className='manufacturers'>
            <div className='manufacturers__list'>
                {items.map((item: Item) => (
                    <div key={item.id}>
                        <img src={item.image} alt={item.alt} />
                    </div>
                ))}
            </div>
        </div>
    )
}