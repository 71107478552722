import React from 'react'
import { Link, useParams } from 'react-router-dom'
import categoriesData from '../data/categories.json'
import '../scss/breadcrumbs.scss'

interface Subcategory {
    id: number
    slug: string
    name: string
}

interface Category {
    id: number
    slug: string
    category: string
    subcategories: Subcategory[]
}

const Breadcrumbs: React.FC = () => {
    const { categorySlug, subcategorySlug } = useParams<{ categorySlug: string, subcategorySlug: string }>()
    const category = categoriesData.find((category: Category) => category.slug === categorySlug)
    const subcategory = category?.subcategories.find((subcategory: Subcategory) => subcategory.slug === subcategorySlug)
    const subcategoryTitle = subcategory?.name

    return (
        <div className='breadcrumbs'>
            <Link to={`/categories`}>Продукция</Link>
            <span> / </span>
            <Link to={`/${categorySlug}`}>{category?.category}</Link>
            <span> / </span>
            <span>{subcategoryTitle}</span>
        </div>
    )
}

export default Breadcrumbs
