import '../../scss/about.scss'
import React from 'react'

const About: React.FC = () => {
    return (
        <div className='about'>
            <h2>Производим гидроцилиндры и маслостанции.</h2>
            <h3>Продаём со склада и поставляем на заказ гидрооборудование с 2003-го года.</h3>
            <div className='about__text'>
                <div className='left-text'>
                    <ul>
                        <li>Собственное производство</li>
                        <li>Проектирование маслостанций и гидроцилиндров</li>

                    </ul>
                </div>
                <div className='right-text'>
                    <ul>
                        <li>Гидрокомпоненты лучших европейских производителей</li>
                        <li>Отличные условия поставки любой гидравлики</li>
                    </ul>
                </div>
            </div>
            <p>Широкий ассортимент компонентов для сборки маслостанций, гидроцилиндров и трубопроводов на
                складе</p>
        </div>
    )
}

export default About
