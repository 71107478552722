import React from 'react'
import { YandexMap } from '../components/YandexMap'
import { FaEnvelope, FaSquarePhone, FaSquareWhatsapp } from 'react-icons/fa6'
import '../scss/contacts.scss'
import { Helmet } from 'react-helmet'

const Contacts: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Контакты - ЕвроПромГидравлик</title>
                <meta name='description'
                      content='Контактная информация компании «ЕвроПромГидравлик». Официальный дилер гидравлического оборудования от европейских производителей.' />
            </Helmet>
            <div className='contacts'>


                <h1>Контакты</h1>
                <div className='contacts__inner'>
                    <div className='contacts__inner-tel'>
                        <p><FaSquarePhone size='1.5em' className='contacts__inner-icons' />
                            <a href='tel:78123362937'>+7 (812) 336 29 37</a>
                        </p>
                        <p><FaSquareWhatsapp size='1.5em' className='contacts__inner-icons' /><a
                            href='https://wa.me/79658198225'>+7 (965) 819 82 25</a>
                        </p>
                        <p><FaEnvelope size='1.5em' className='contacts__inner-icons' /><a
                            href='mailto:info@avs-rus.ru'> info@avs-rus.ru</a></p>
                    </div>

                    <div className='contacts__inner-address'>
                        <p> ООО "ЕвроПромГидравлик"</p>
                        <p>192289, Россия, Санкт-Петербург, пр. Девятого января, д.57,лит.А-А1</p>
                        <p>Пн-Чт: 9:00-17:30 Пт: 9:00-16:30</p>
                    </div>
                </div>
                <div className='ymap'>
                    <YandexMap />
                </div>
            </div>
        </>
    )
}

export default Contacts
